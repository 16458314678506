<template>
  <div>
    <Swiper :swiperList="swiperList"></Swiper>
    <Loading v-show="swiperShopList.length == 0 || homeList.length == 0"></Loading>
    <div class="con_list" v-show="swiperShopList.length != 0 && homeList.length != 0">
      <div class="box">
        <div class="swiperBox">
          <img
            @click="rightClick"
            class="icon right"
            src="https://static.5t1800.com/s2/2021/06/11/60c304f83339c.png"
            alt
          />
          <div class="hidBox noSel">
            <ul
              class="list flex a_c"
              :style="{ transform: `translateX(${transformNum * -406}px)` }"
            >
              <li v-for="(item, i) in swiperShopList" :key="i">
                <div>
                  <img width="100%" height="386px" :src="item.img_path" alt />
                </div>
                <div class="shopBox">
                  <div class="p1">{{ item.title }}</div>
                  <div class="p2">{{ item.jieshao }}</div>
                </div>
                <div class="shopBox_bot flex j_s a_c">
                  <div class="price">￥{{ item.price | keepTwoNum }}</div>
                  <div class="buy">立即购买</div>
                </div>
              </li>
            </ul>
          </div>
          <img
            @click="leftClick"
            class="icon left"
            src="https://static.5t1800.com/s2/2021/06/11/60c304d26b3f9.png"
            alt
          />
        </div>
        <div class="listBox">
          <ul class="list flex a_c j_s">
            <li v-for="(item, i) in homeList" :key="i">
              <div>
                <img width="100%" height="386px" :src="item.img_path" alt />
              </div>
              <div class="shopBox">
                <div class="p1">{{ item.title }}</div>
                <div class="p2">{{ item.jieshao }}</div>
              </div>
              <div class="shopBox_bot flex j_s a_c">
                <div class="price">￥{{ item.price | keepTwoNum }}</div>
                <div class="buy">立即购买</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "../../../components/swiper.vue";
import Loading from "../../../components/loading.vue";
import api from "@/api/api";
export default {
  data() {
    return {
      swiperList: [
        "https://static.5t1800.com/s2/2021/06/10/60c1d7dc618c4.jpg",
        "https://static.5t1800.com/s2/2021/06/10/60c1d7dc618c4.jpg",
        "https://static.5t1800.com/s2/2021/06/10/60c1d7dc618c4.jpg"
      ],
      swiperShopList: [
        {
          costprice: "1.00",
          ctime: "2021-06-25 09:45:36",
          desc: "无",
          dist: 0,
          dist_rebate: 0,
          dist_rebate_cloud: 0,
          dist_rebate_star: 0,
          goods_type: "goods",
          homepage_img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c304a525092.png",
          id: 294,
          img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c2ffca426d6.png",
          limit_num: 0,
          mktprice: 0,
          price: "0.01",
          sku_id: 441,
          sku_title: "80ml",
          specification: 1,
          title: "测试1"
        },
        {
          costprice: "1.00",
          ctime: "2021-06-25 09:45:36",
          desc: "无",
          dist: 0,
          dist_rebate: 0,
          dist_rebate_cloud: 0,
          dist_rebate_star: 0,
          goods_type: "goods",
          homepage_img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c304a525092.png",
          id: 294,
          img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c2ffca426d6.png",
          limit_num: 0,
          mktprice: 0,
          price: "0.01",
          sku_id: 441,
          sku_title: "80ml",
          specification: 1,
          title: "测试1"
        },
        {
          costprice: "1.00",
          ctime: "2021-06-25 09:45:36",
          desc: "无",
          dist: 0,
          dist_rebate: 0,
          dist_rebate_cloud: 0,
          dist_rebate_star: 0,
          goods_type: "goods",
          homepage_img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c304a525092.png",
          id: 294,
          img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c2ffca426d6.png",
          limit_num: 0,
          mktprice: 0,
          price: "0.01",
          sku_id: 441,
          sku_title: "80ml",
          specification: 1,
          title: "测试1"
        },
        {
          costprice: "1.00",
          ctime: "2021-06-25 09:45:36",
          desc: "无",
          dist: 0,
          dist_rebate: 0,
          dist_rebate_cloud: 0,
          dist_rebate_star: 0,
          goods_type: "goods",
          homepage_img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c304a525092.png",
          id: 294,
          img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c2ffca426d6.png",
          limit_num: 0,
          mktprice: 0,
          price: "0.01",
          sku_id: 441,
          sku_title: "80ml",
          specification: 1,
          title: "测试1"
        }
      ],
      homeList: [
        {
          costprice: "1.00",
          ctime: "2021-06-25 09:45:36",
          desc: "无",
          dist: 0,
          dist_rebate: 0,
          dist_rebate_cloud: 0,
          dist_rebate_star: 0,
          goods_type: "goods",
          homepage_img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c304a525092.png",
          id: 294,
          img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c2ffca426d6.png",
          limit_num: 0,
          mktprice: 0,
          price: "0.01",
          sku_id: 441,
          sku_title: "80ml",
          specification: 1,
          title: "测试1"
        },
        {
          costprice: "1.00",
          ctime: "2021-06-25 09:45:36",
          desc: "无",
          dist: 0,
          dist_rebate: 0,
          dist_rebate_cloud: 0,
          dist_rebate_star: 0,
          goods_type: "goods",
          homepage_img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c304a525092.png",
          id: 294,
          img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c2ffca426d6.png",
          limit_num: 0,
          mktprice: 0,
          price: "0.01",
          sku_id: 441,
          sku_title: "80ml",
          specification: 1,
          title: "测试1"
        },
        {
          costprice: "1.00",
          ctime: "2021-06-25 09:45:36",
          desc: "无",
          dist: 0,
          dist_rebate: 0,
          dist_rebate_cloud: 0,
          dist_rebate_star: 0,
          goods_type: "goods",
          homepage_img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c304a525092.png",
          id: 294,
          img_path:
            "https://static.5t1800.com/s3/2021/06/11/60c2ffca426d6.png",
          limit_num: 0,
          mktprice: 0,
          price: "0.01",
          sku_id: 441,
          sku_title: "80ml",
          specification: 1,
          title: "测试1"
        }
      ],
      transformNum: 0,
      loading: true
    };
  },
  components: {
    Swiper,
    Loading
  },
  created() {
    this.$store.commit("saveTypeIndex", 1);
    // this.getSwiperShop();
    // this.getHomeShop()
  },
  methods: {
    //获取轮播商品列表
    getSwiperShop() {
      api
        .$getSwiperList()
        .then(res => {
          console.log(res.data);
          this.swiperShopList = res.data.swiperList;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取首页商品列表
    getHomeShop() {
      api
        .$getHomeList()
        .then(res => {
          console.log(res.data);
          this.homeList = res.data.homeList;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //左点击
    leftClick() {
      if (--this.transformNum < 0) {
        this.transformNum = 0;
      }
    },
    //右点击
    rightClick() {
      var listNum = this.swiperShopList.length - 3;
      console.log(listNum);
      if (this.transformNum < listNum) {
        this.transformNum++;
      }
    }
  }
};
</script>

<style scoped>
.con_list {
  background: #ffffff;
}
.con_list .box {
  padding: 80px 0;
}
.con_list .box .swiperBox {
  position: relative;
  width: 1220px;
  margin: 0 auto;
}
.con_list .box .swiperBox .list {
  transition: all 0.3s;
}
.con_list .box .swiperBox .list li {
  height: 555px;
  width: 386px;
  margin: 0 10px;
  flex-shrink: 0;
}
.hidBox {
  width: 1220px;
  overflow: hidden;
}
.swiperBox .icon {
  height: 23px;
  width: 11px;
  position: absolute;
  top: calc(50% - 11.5px);
  cursor: pointer;
}
.swiperBox .left {
  left: -50px;
}
.swiperBox .right {
  right: -50px;
}

.con_list .box .listBox {
  width: 1200px;
  margin: 60px auto;
}

.con_list .box .list {
  width: 100%;
}

.con_list .box .list li {
  width: 386px;
}

/* 商品详情 */
.shopBox {
  padding: 0 11px;
  height: 122px;
}

.shopBox .p1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 20px 0 15px 0;
}

.shopBox .p2 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.shopBox_bot {
  height: 47px;
  padding: 10px;
  box-sizing: border-box;
}
.shopBox_bot .buy {
  width: 78px;
  height: 27px;
  background: #000000;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 27px;
}
.shopBox_bot .price {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff2742;
}
</style>

